/* eslint-disable @next/next/no-sync-scripts */
'use client';

import Image from 'next/image';
import Link from 'next/link';

import Email from '@/assets/email.svg';
import Instagram from '@/assets/instagram.svg';
import Logo from '@/assets/logo.png';
import styles from './Header.module.css';

const Header = () => {
    return (
        <>
            <header className={styles.header}>
                <div className={styles.container}>
                    <Image src={Logo} alt="logo" className={styles.logo} />
                    <div className={styles.media}>
                        <Link href="https://www.instagram.com/sergio.zyone" target="_blank">
                            <Image
                                className={styles.mediaIcon}
                                src={Instagram}
                                alt="instagram"
                                width={38}
                                height={38}
                            />
                        </Link>
                        <Link href="mailto:sergiosaldanam@gmail.com" target="_blank">
                            <Image className={styles.mediaIcon} src={Email} alt="email" width={38} height={38} />
                        </Link>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
